// index.scss is the entry point for all style files. If you add a new file
// be sure to include an import in index.scss.
// A potential refactor in the future is to move to a modular styling system where we use the
// [componentName].module.scss convention to pull in styles.
// This will eliminate the need for an index.scss import as well as remove global styling.
// This is something to bear in mind if/when adding additional styles - in this project, styles
// are global unless scoped using CSS strategies
import "../styles/index.scss"
import { TrackingProvider } from "../contexts/trackers"
import { XStateProvider } from "../contexts/XStateCtx"
import { SegmentAnalytics } from "../components/SegmentAnalytics"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

const queryClient = new QueryClient()

function MyApp({ Component, pageProps: { ...pageProps } }) {
  return (
    <SegmentAnalytics apiKey={process.env.NEXT_PUBLIC_SEGMENT_KEY}>
      <TrackingProvider>
        <XStateProvider>
          <QueryClientProvider client={queryClient}>
            <Component {...pageProps} />
          </QueryClientProvider>
        </XStateProvider>
      </TrackingProvider>
    </SegmentAnalytics>
  )
}

export default MyApp
